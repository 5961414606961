import jQuery from 'jquery';
import 'slick-carousel';
import fullpage from 'fullpage.js';
const isPhoneDevice = "ontouchstart" in document.documentElement;
jQuery(function($) {
  const beepColor = (id, step) => {
    $(id).parent().addClass('error');
    setTimeout(() => {
      $(id).parent().removeClass('error');
      if(step < 5) {
        setTimeout(() => {
          beepColor('#iagree', step+1);
        }, 500);
      }
    }, 500)
  }
  $('#sendFormJs').submit(() => {
      const iagree = $('#iagree').prop('checked');
      if(!iagree) {
        beepColor('#iagree', 0);
        return false;
      }
      const field = $('#sendFormJs').serialize();

      $.get( `https://gri.leadforms.ru/?${field}`, () => {
        $('.form--box').html('<h2>Заказ оформлен, спасибо!</h2><br><br><div class="form--title text-center">Ожидайте звонка менеджера.</div>');
      }).fail((err) => {
        $('.form--box').html(`<h2>Ошибка!</h2><br><br><div class="form--title text-center">${JSON.stringify(err)}</div>`);
      });

      return false;
  });
  $('#lk-slick').slick({
    dots: true,
    infinite: true,
  });

  if($(window).width() > 770) {
    $(document).find('a[name]').remove();
    var myFullpage = new fullpage('#fullpage', {
      anchors: ['main', 'technology', 'price', 'news', 'form'],
      licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE'
    });
  } else {
    $('.how-much, .how-it-work').addClass('active')
  }

  $('menu').click((res) => {
    $('menu').toggleClass('active');
  })
});
